import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import { thenResolve } from '@/utils/index'

const deliveryServices = {
  /**
   * 获取点检列表
   */
  async getInspecitionList(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/task/carList`, params)
  },
  /**
   * 车辆点检
   */
  async queryInspect(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/task/inspect`, params).then(thenResolve)
  },
  /**
   * 分配
   */
  async inspectAssign(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/task/assign`, params)
  },
  /**
   * 点检记录
   */
  async inspectHistoryList(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/task/list`, params).then(thenResolve)
  },
  /**
   * 点检详情
   */
  async inspectDetail(params) {
    return await request.post(`${SERVER.Test_Drive}/client/v1/task/detail`, params).then(thenResolve)
  },
}
export default deliveryServices
