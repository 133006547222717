<script>
import dayjs from 'dayjs'
import { getCalendarList } from '@/services/appointment'
import deliveryServices from '@/services/deliveryServices'
import { dateFormat } from '@/utils'
const date = dayjs()
export default {
  props:{
    showNum:  {
      type: Boolean,
      default: false
    },
    maxHeight:{
      type: String,
      default:'320px'
    },
    initDate:{
      type: [Date,Object,Function,String,Number],
      default: ()=>date
    },
    minDate:{
      type: [Date,Object,Function,String,Number],
      default: ()=> date.subtract(12, 'month')
    },
    maxDate:{
      type: [Date,Object,Function,String,Number],
      default: ()=> date.add(12, 'month')
    },
    appointmentType:{
      type: String,
      default:''
    },
    isAutoScrollTop:{
      type: Boolean,
      default:false
    },
    disabledHistory:{
      type: Boolean,
      default: false
    },
    regular: {
      type: Boolean,
      default: false
    }
  },
  data() {    
    return {
      planArriveDate: date,
      nDate: date,
      planDate: {},
      calendarStore:{},
      isLoading: false
    }
  },
  watch:{
    initDate:{
      handler(val){
        if (val){
          this.planArriveDate = this.dateFormat(val)
        }
      },
      immediate: true,
      deep: true
    },
  },
  created(){
    this.initCalendar()
  },
  async mounted() {
    setTimeout(()=>{
      if (this.isAutoScrollTop) {
        this.calendarScrollTop()
      }      
    })
  },
  methods:{
    initCalendar() {
      const nowDate = dayjs()
      this.planDate = {}
      this.isLoading=false
      this.calendarStore={
        current:'',
        list:[],
        startDate:'',
        endDate:''
      }
      this.monthShow({ date: `${nowDate.year()}-${nowDate.month()+1}-1` }, true)
    },
    // 预约日期蓝色点
    getDot({ date }) {
      const reDate = this.dateFormat(date)
      return this.planDate[reDate] || 0
    },
    // 时间格式
    dateFormat(date, config) {
      const { type, postfix } = Object.assign(
        { type: 'YYYY-MM-DD', postfix: '' },
        config
      ) 
      if (date) {
        let myDate = dayjs(date).format(type)
        switch (postfix) {
          case 'start':
            myDate = myDate + ' 00:00:00'
            break
          case 'end':
            myDate = myDate + ' 23:59:59'
            break
        }
        return myDate
      }
      return ''
    },
    formatter(day) {
      if (this.nDate.isSame(dayjs(day.date), 'date')) {
        day.text = this.$t('今')
        day.className = 'nowDay'
      } 
      else if ( !this.nDate.isBefore(dayjs(day.date), 'date') && this.disabledHistory){
        day.type = 'disabled'
      }
      if (this.regular && this.nDate.isSame(dayjs(day.date), 'date') && this.disabledHistory) {
        day.type = 'disabled'
      }
      if (dayjs(this.planArriveDate, 'date').isSame(dayjs(day.date), 'date')){
        day.type = 'selected'
      }
      if (!this.planArriveDate){
        day.type=''
      }
      return day
    },
    // date1比date2小
    dayIsBefore(date1,date2,type='month') {
      return dayjs(date1).isBefore(dayjs(date2),type)
    },
    // date1比date2大
    dayIsAfter(date1,date2,type='month') {
      return dayjs(date1).isAfter(dayjs(date2),type)
    },
    monthShow({ date,title },init) {
      if (!this.appointmentType && !this.showNum) return
      // pc 显示3个月，默认当前月+3，当前月-1
      // 缓存
      // 下翻+2
      // 上翻 -2
      const { list,current } = this.calendarStore
      if (!current && !init) return
      const nowDate = this.dateFormat(date)
      let prev = 0,next = 0
      if (list.length === 0) {
        next +=3
        prev +=1
      } else if (this.dayIsBefore(current,date)){ // 下
        next += 4
      } else if (this.dayIsAfter(current,date)) { // 上
        prev += 4
      }
      const dateArr = []
      !list.includes(nowDate) && dateArr.push(nowDate)
      for (let i = 1; i <= next; i++) {
        const da = this.dateFormat(dayjs(date).add(i,'month'))
        !list.includes(da) && this.dayIsBefore(date,this.maxDate) && dateArr.push(da)
      }
      for (let i = 1; i <= prev; i++) {
        const da = this.dateFormat(dayjs(date).subtract(i,'month'))
        !list.includes(da) && dateArr.unshift(da)
      }
      if (dateArr.length && dateArr.length > 1){
        // this.calendarStore.current = date
        this.calendarStore.list = this.calendarStore.list.concat(dateArr)
        const min = this.dateFormat(dateArr[0], { postfix: 'start' })
        const max = this.dateFormat(dayjs(dateArr[dateArr.length-1]).add(1,'month'),{ postfix: 'end' })
        console.log(dateArr,min,max)
        Object.assign(this.calendarStore,{
          startDate: min,
          endDate: max
        })
      }
    },
    // 切换日期
    selectDate(date) {
      const { planArriveDate,dateFormat } = this
      this.planArriveDate = dateFormat(date) !== dateFormat(planArriveDate) ? date : planArriveDate
      this.$emit('change',this.planArriveDate)
    },
    calendarScrollTop() {
      try {
        this.$nextTick(() => {
          const body = this.$refs.calendar?.$refs.body || ''
          const nowDay = document.getElementsByClassName('van-calendar__selected-day')[0]
          body.scrollTop =
                nowDay.getBoundingClientRect().top -
                body.getBoundingClientRect().top +
                body.scrollTop
        })
      } catch (error) {}
    },
  }
}
</script>
<template>
  <van-calendar
    ref="calendar"
    class="calendar"
    row-height="60"
    :default-date="planArriveDate?new Date(planArriveDate):null"
    :first-day-of-week="1"
    :poppable="false"
    :show-confirm="false"      
    :formatter="formatter"
    :show-title="false"
    :minDate="new Date(minDate)"
    :maxDate="new Date(maxDate)"
    @month-show="monthShow"
    @select="selectDate">
    <template slot="bottom-info" slot-scope="scope">
    </template>
  </van-calendar>
</template>
<style lang="less" scoped>
@black:#0D171A;
@yellow: #EED484;
.calendar{
  /deep/.van-calendar__footer{
    padding: 0;
  }
  /deep/ .van-calendar__body {
    &::-webkit-scrollbar {
      display: none;
    }
    .van-calendar__day{
      font-weight: 600;
    }
    .van-calendar__selected-day{
      background-color: @black;
      color: @yellow;
      border-radius: 6px;
      width: 46px !important;    
      z-index: 1;  
    }
    .dot{
      color: @yellow-text;
    }
    .nowDay {
      position: relative;
      &::before {
        content: "今";
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100%;
        height: 32px;
        line-height: 32px;
        border-radius: 6px;
        box-sizing: border-box;
      }
      &::before{
        line-height: 60px;
        width: 46px;
        height: 60px;
        background: #E5E5E5;
      }
    }
  }
  /deep/ .van-calendar__bottom-info{
    bottom: 4px;
    font-weight: 400;
  }
}
</style>
