<script>
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import 'dayjs/locale/zh-cn'
import { dateFormat } from '@/utils'
import inspectionServices from '@/services/inspectionServices'
import loading from '@/utils/loading'
const date = dayjs()
dayjs.extend(weekday)
dayjs.locale('zh-cn')
export default {
  props:{
    initDate:{
      type: [Date,Object,Function,String,Number],
      default: ()=>date
    },
    tableType:{
      type: String,
      default:''
    }
  },
  data() {
    return {
      nDate: date,
      dateData:{        
        0:{
          title:this.$t('周一'),
          date:'',
          day: '',
          className:'',
          text:''
        },
        1:{
          title:this.$t('周二'),
          date:'',
          day: '',
          className:'',
          text:''
        },
        2:{
          title:this.$t('周三'),
          date:'',
          day: '',
          className:'',
          text:''
        },
        3:{
          title:this.$t('周四'),
          date:'',
          day: '',
          className:'',
          text:''
        },
        4:{
          title:this.$t('周五'),
          date:'',
          day: '',
          className:'',
          text:''
        },
        5:{
          title:this.$t('周六'),
          date:'',
          day: '',
          className:'',
          text:''
        },
        6:{
          title: this.$t('周日'),
          date:'',
          day: '',
          className:'',
          text:''
        },
      },
      num:{}
    }
  },
  watch:{
    initDate:{
      handler(val){
        if (val){
          this.initList()
        }
      },
      immediate: true
    }
  },
  mounted() {

  },
  methods:{
    initList(){
      let { initDate, dateData } = this
      if (!initDate)initDate = dayjs()
      if (initDate){
        for (const key in dateData) {
          const item = dateData[key]
          item.text = ''
          item.className = ''
          const date = dayjs(initDate).weekday(key)
          this.nDate.isSame(dayjs(date), 'date') && (item.text = this.$t('今'))
          item.value = dateFormat(date,'YYYY-MM-DD')
          item.day = dateFormat(date,'D')
        }
        this.getInspectionList()
      }
    },
    getSameClassName(date) {
      return this.initDate ? dayjs(this.initDate).isSame(dayjs(date),'date') : ''
    },
    changeDate(item){
      this.$emit('change',this.getSameClassName(item.value) ? '': item.value, item)
    },
    // 获取数据
    async getInspectionList() {
      const params = {
        testDriveCarId: this.$route.query.id,
        endDate: this.dateData[6].value,
        startDate: this.dateData[0].value
      }
      loading.showLoading()
      const res = await inspectionServices.inspectHistoryList(params)
      const { taskCount, taskMap = {} } = res
      this.num = taskMap
      this.$emit('showInfo', taskMap)
      loading.hideLoading()
    },
    // 重新计算
  }
}
</script>

<template>
  <div class="dateList">
    <div class="van-calendar__header">
      <div class="van-calendar__weekdays">
        <div v-for="(item,key) in dateData" :key="key" class="van-calendar__weekday">{{ item.title }}</div>
      </div>
    </div>
    <div class="van-calendar__days">
      <div v-for="(item,key) in dateData" :key="key" class="li van-calendar__day" @click.stop="changeDate(item)">
        <div :class="{nowDay:!!item.text,'van-calendar__selected-day':!!getSameClassName(item.value)}">
          <span style="font-weight: 600;">{{ item.text || item.day }}</span>
          <div class="van-calendar__bottom-info" style="bottom: 4px;">
            <div :class="['dot', num[item.value] ? (num[item.value]?.flag ? 'pass' : 'fail') : '']"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.dateList{
  background-color: #fff;
  .van-calendar__days{
    .li{
      height: 60px;
      >div{
        min-width: 46px;
      }
      .van-calendar__selected-day,.nowDay{
        height: 100%;
      }
      .dot{
        width: 10px;
        height: 10px;
        border-radius: 10px;
        &.pass{
          background: green;
        }
        &.fail{
          background: red;
        }
      }
      .nowDay{
        line-height: 60px;
        background: #E5E5E5;
        border-radius: 6px;
      }
    }
  }
  /deep/ .van-calendar__bottom-info{
    bottom: 4px;
    display: flex;
    justify-content: center;
  }
}
</style>
